.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile + label {
    font-weight: 700;
    color: white;
    background-color: rgb(99,34,34, 0.7);
    display: inline-block;
    font-family: 'Source Sans Pro', sans-serif;
    padding: 20px;
}

.inputfile:focus + label,
.inputfile + label:hover {
    background-color: #632222;
}

.inputfile + label {
	cursor: pointer; /* "hand" cursor */
}

.inputfile:focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}
