body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'fontello';
  src: url(/static/media/fontello.e59dd8d1.eot);
  src: url(/static/media/fontello.e59dd8d1.eot#iefix) format('embedded-opentype'),
       url(/static/media/fontello.4c8c09cb.woff2) format('woff2'),
       url(/static/media/fontello.3d348e17.woff) format('woff'),
       url(/static/media/fontello.d9950790.ttf) format('truetype'),
       url(/static/media/fontello.b65fe7ff.svg#fontello) format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon-icon-isotype:before { content: '\E800'; } /* '' */
.icon-icon-minimal-isotype:before { content: '\E801'; } /* '' */
.icon-icon-seal:before { content: '\E802'; } /* '' */
.icon-icon-seal-simplified:before { content: '\E803'; } /* '' */
.icon-icon-seal-simplified-1:before { content: '\E804'; } /* '' */

.demo-icon
    {
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      width: 1em;
      margin-right: .2em;
      text-align: center;
      color: #632222;
    }
.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile + label {
    font-weight: 700;
    color: white;
    background-color: rgb(99,34,34, 0.7);
    display: inline-block;
    font-family: 'Source Sans Pro', sans-serif;
    padding: 20px;
}

.inputfile:focus + label,
.inputfile + label:hover {
    background-color: #632222;
}

.inputfile + label {
	cursor: pointer; /* "hand" cursor */
}

.inputfile:focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile + label {
    font-weight: 700;
    color: white;
    background-color: rgb(99,34,34, 0.7);
    display: inline-block;
    font-family: 'Source Sans Pro', sans-serif;
    padding: 20px;
}

.inputfile:focus + label,
.inputfile + label:hover {
    background-color: #632222;
}

.inputfile + label {
	cursor: pointer; /* "hand" cursor */
}

.inputfile:focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}

