@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?57619785');
  src: url('../font/fontello.eot?57619785#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?57619785') format('woff2'),
       url('../font/fontello.woff?57619785') format('woff'),
       url('../font/fontello.ttf?57619785') format('truetype'),
       url('../font/fontello.svg?57619785#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon-icon-isotype:before { content: '\e800'; } /* '' */
.icon-icon-minimal-isotype:before { content: '\e801'; } /* '' */
.icon-icon-seal:before { content: '\e802'; } /* '' */
.icon-icon-seal-simplified:before { content: '\e803'; } /* '' */
.icon-icon-seal-simplified-1:before { content: '\e804'; } /* '' */

.demo-icon
    {
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      width: 1em;
      margin-right: .2em;
      text-align: center;
      color: #632222;
    }